import mitt from 'mitt';

const eventBus = mitt();

export { eventBus };

export function dataLoaderResults(data) {
  eventBus.emit('dataLoaderResults', data);
}
export function uploadComplete(type, data) {
  eventBus.emit(type, data);
}
