<template>
  <div>
    <b-field class="file">
      <b-upload
        v-model="file"
        :loading="isLoading"
        accept=".csv"
        :native="true"
        @input="sendFile"
      >
        <a class="button is-primary">
          <b-icon icon="upload"></b-icon>
          <span>Click to upload</span>
        </a>
      </b-upload>
      <span v-if="file" class="file-name">
        {{ file.name }}
      </span>
    </b-field>
    <b-message
      v-if="error"
      :title="$t('There was a problem while processing the file')"
      type="is-danger"
    >
      <strong>
        <p>{{ error.source }}</p>
        <p>Error code: {{ error.statusCode }}</p>
        <p>Error message: {{ error.message }}</p>
        <br />
        <p>{{ $t('Please try again or contact an administrator') }}</p>
      </strong>
    </b-message>
  </div>
</template>

<script>
import axios from 'axios';
import { uploadComplete, dataLoaderResults } from '@/events/events';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'FileLoader',
  props: {
    url: {
      type: String,
      required: true,
    },
    environment: {
      type: String,
      required: false,
      default: null,
    },
    queryParams: {
      type: Array,
      required: false,
      default: null,
    },
    emitType: {
      type: String,
      required: false,
      default: null,
    },
    removeFile: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      error: null,
      file: null,
    };
  },
  computed: {
    requestHeader() {
      const auth = `Bearer ${this.idToken}`;
      let headerObj = {
        Authorization: auth,
        customer: this.customer,
        environment: this.environment,
        userId: this.userId,
      };
      return headerObj;
    },
    uploadURL() {
      let url = `${this.url}`;
      let params = new URLSearchParams();
      if (this.queryParams && this.queryParams.length > 0) {
        this.queryParams.forEach((param) => {
          params.append(param.key, param.value);
        });
      }
      // only append if we have some keys
      if (!params.keys().next().done) {
        url += `?${params.toString()}`;
      }
      return url;
    },
  },
  methods: {
    async sendFile(file) {
      this.$emit('upload-started');
      this.error = null;
      const url = this.uploadURL;

      this.isLoading = true;
      const formData = new FormData();
      formData.append('csv', file);
      try {
        const { data } = await axios.post(url, formData, {
          headers: this.requestHeader,
        });
        this.handleSuccess(data);
      } catch (err) {
        this.handleError(err);
        console.error(err);
      } finally {
        this.isLoading = false;
      }
    },
    handleSuccess(data) {
      this.isLoading = false;
      this.$emit('upload-complete');
      if (!this.emitType) {
        dataLoaderResults(data);
      } else {
        uploadComplete(this.emitType, data);
      }
      this.file = null;
    },
    handleError(err) {
      this.file = [];
      console.error('fileLoader.handleError', err);
      this.$emit('upload-complete');
      this.isLoading = false;
      if (this.isErrorUnauthorised(err)) {
        this.$store.dispatch('storeAuth/logout');
      }

      if (err.message) {
        try {
          this.error = JSON.parse(err.message);
        } catch (parseError) {
          console.error(parseError.message);
          this.error = err;
        }
      } else {
        this.error = {
          source: 'Unknown',
          statusCode: 400,
          message: `Unknown error processing file: ${JSON.stringify(err)}`,
        };
      }
    },
  },
});
</script>
