<template>
  <div>
    <div v-if="!errorOnly">
      <p class="has-text-success is-size-4">{{ $t('Successful') }}</p>
      <b-table
        :data="successData"
        :paginated="true"
        :columns="successColumns"
        style="overflow-x: auto"
        :default-sort="defaultSortColumn"
        :default-sort-direction="defaultSortDirection"
      ></b-table>
    </div>
    <hr />
    <div v-if="!successOnly">
      <p class="has-text-danger is-size-4">{{ $t('Failed') }}</p>
      <b-table
        :data="errorData"
        :paginated="true"
        :columns="errorColumns"
        style="overflow-x: auto"
      ></b-table>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'ResultTable',
  props: {
    successData: {
      type: Array,
      required: true,
    },
    errorData: {
      type: Array,
      required: true,
    },
    successColumns: {
      type: Array,
      required: true,
    },
    errorColumns: {
      type: Array,
      required: true,
    },
    successOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
    errorOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
    defaultSortColumn: {
      type: String,
      required: false,
      default: '',
    },
    defaultSortDirection: {
      type: String,
      required: false,
      default: 'asc',
    },
  },
});
</script>
